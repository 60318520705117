import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'

import { PRODUCTS } from 'lib/products/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import DefaultLayout from 'layouts/DefaultLayout'
import ControlContent from 'views/wills/components/ControlLP'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'

import Hero from './Hero'

const StyledWrapper = styled(Wrapper)`
  padding-top: ${GTR.S};
  padding-bottom: ${GTR.S};
  @media screen and (min-width: ${BREAKPOINT.M}px) {
    padding-top: ${GTR.XL};
  }
`

export default function Beyond(): ReactElement {
  return (
    <DefaultLayout
      title="Online Will Writing Service | Legal Will In 15 Minutes"
      description={`Our solicitor-approved guide helps you write a will online from the comfort of your own home. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot. National Will Writing Firm of the Year.`}
      product={PRODUCTS.WILLS}
      headerShowLoginLink
      footerShowSelectedProductLinks={false}
    >
      <Wrapper background={COLOR.WHITE}>
        <StyledWrapper container>
          <Hero />
        </StyledWrapper>
      </Wrapper>
      <ControlContent />
    </DefaultLayout>
  )
}
